<template>
    <div>
        <div>
            <multiselect v-model="selected"
                         label="text"
                         track-by="value"
                         open-direction="bottom"
                         :placeholder="$t('search')"
                         :class="validateError != '' ? 'box-border-color' : ''"
                         :options="options"
                         :searchable="true"
                         :loading="isLoading"
                         :internal-search="false"
                         :close-on-select="true"
                         :show-no-results="true"
                         :select-label="''"
                         :selected-label="''"
                         :deselect-label="''"
                         @search-change="search"
                         @input="handleInput"
            >
                <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.text }}
                </template>
                <span slot="noOptions"></span>
                <span slot="noResult">{{ $t('no_result') }}</span>
            </multiselect>
        </div>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
    </div>
</template>
<script>
    // Helpers
    import setSelected from '@/helpers/setSelected';

    // Services
    import StaffService from '@/services/StaffService';

    export default {
        props: {
            value: {
                default: null
            },
            valueObject: {
                type: Object,
                default: null
            },
            validateError: {
                type: String,
                default: ''
            },
            returnType: {
                type: String,
                default: 'id'
            },
            returnValue: {
                type: String,
                default: 'user_id'
            }
        },
        created() {
            if (this.valueObject) {
                if (this.valueObject.value && this.valueObject.text) {
                    this.options.push(this.valueObject);
                }
                this.selected = this.setSelected(this.value, this.options)
            }
        },
        watch: {
            value: function(newValue) {
                if (newValue) {
                    if (this.options.length > 0) {
                        if (this.returnType == 'id') {
                            this.selected = this.options.filter(c => c.value == newValue);
                        }
                        else {
                            this.selected = newValue
                        }
                    }
                }
                else {
                    this.selected = null;
                }
            },
            valueObject: {
                deep: true,
                handler: function(newValue, oldValue) {
                    if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                        if (newValue.value && newValue.text) {
                            this.options.push(newValue);
                        }
                        this.selected = this.setSelected(this.value, this.options)
                    }
                }
            }
        },
        data() {
            return {
                isLoading: false,
                options: [],
                selected: []
            }
        },
        methods: {
            setSelected: setSelected,
            handleInput(event) {
                if (event && event.value) {
                    if (this.returnType == 'id') {
                        this.$emit('input', event.value);
                    }
                    else {
                        this.$emit('input', event);
                    }
                }
                else {
                    this.$emit('input', null);
                }
            },
            search(key) {
                if (key.length > 2) {
                    const config = {params: {key: key}}

                    this.isLoading = true
                    StaffService.search(config)
                                .then((response) => {
                                    let data = response.data.data;
                                    this.options = []
                                    data.forEach((item) => {
                                        this.options.push({
                                            value: item[this.returnValue] ? item[this.returnValue] : item.user_id,
                                            text: item.name + ' ' + item.surname
                                        });
                                    });
                                })
                                .then(() => {
                                    if (this.value) {
                                        this.selected = this.options.filter(c => c.value == this.value);
                                    }
                                })
                                .finally(() => {
                                    this.isLoading = false
                                })
                }
            }

        }
    }
</script>

const routes = [
    {
        path: "/constants",
        component: () => import("../pages/Index"),
        meta:{
            isAuthenticated: true,
            permission:"constant_index"
        }
    }
]

export default routes;

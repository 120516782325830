const userModule = {
    namespaced: true,
    state: () => ({
        user: {
            name: 'sezay',
            surname: 'ozturk',
        },
        count: 0
    }),
    getters: {
        getCount(state) {
            return state.count;
        }
    },
    mutations: {},
    actions: {}

}

export default userModule;

<template>
    <div class="input-icon position-relative">
        <b-form-input :type="passwordShowStatus ? 'text':'password'"
                      v-model="selected"
                      :class="validateError != '' ? 'box-border-color':''"
                      @input="handleInput"
        />
        <b-form-invalid-feedback v-if="validateError" v-html="validateError"></b-form-invalid-feedback>
        <i class="ri-eye-close-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
           @click="passwordShowStatus=true"
           v-if="!passwordShowStatus"></i>
        <i class="ri-eye-2-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
           @click="passwordShowStatus=false"
           v-if="passwordShowStatus"></i>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                default: null
            },
            validateError: {
                type: String,
                default: ''
            }
        },
        watch: {
            value: function(newValue) {
                this.selected = newValue
            }
        },
        data() {
            return {
                selected: this.value,
                passwordShowStatus: false
            }
        },
        created() {
            this.timer = this.timerFrom;
        },
        methods: {
            handleInput(event) {
                this.$emit('input', event)
            }
        }
    };
</script>

import Api from '@/services/Index';

const login = async (user) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_AUTH;
    return Api.post('/auth/login', user);
}
const loginTwoFactor = async (user) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_AUTH;
    return Api.post('/auth/twofactor', user);
}

const passwordReset = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/auth/password/reset/info', formData);
}

const passwordResetWithMobileNumberSms = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    const form = {
        mobile_tel: formData.mobileNumber,
        email: formData.email
    };
    return Api.post('/auth/password/reset/sms', form);
}
const passwordResetWithMobileNumberSmsVerify = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    const form = {
        token: formData.token,
        sms_code: formData.sms
    };
    return Api.post('/auth/password/reset/verifysms', form);
}
const passwordResetNewPassword = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    const form = {
        token: formData.token,
        password: formData.password,
        password_confirmation: formData.passwordConfirmation
    };
    return Api.post('/auth/password', form);
}

const changePassword = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_AUTH;
    return Api.put('/auth/password/change',formData);
}

const getTranslation = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/language/lines');
}



export default {
    login,
    loginTwoFactor,
    passwordReset,
    passwordResetWithMobileNumberSms,
    passwordResetWithMobileNumberSmsVerify,
    passwordResetNewPassword,
    changePassword,
    getTranslation
}

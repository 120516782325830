const routes = [
    {
        path: "/grade/entry/:courseId?",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "studentcourse_entry"
        }
    },
    {
        path: "/plagiarism",
        component: () => import("../plagiarism"),
        meta: {
            isAuthenticated: true,
            
        }
    },
    {
        path: "/plagiarism/list",
        component: () => import("../plagiarism/list.vue"),
        meta: {
            isAuthenticated: true,
            
        }
    }
];

export default routes;

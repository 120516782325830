const routes = [
    {
        path: "/graduate/application/form",
        component: () => import("../pages/student/ApplicationForm"),
        name: "graduateApplicationForm",
        meta: { }
    },
    {
        path: "/graduate/application/login",
        component: () => import("../pages/student/Login"),
        name: "graduateApplicationLogin",
        meta: { }
    },
    {
        path: "/graduate/application/update",
        component: () => import("../pages/student/ApplicationUpdate"),
        name: "graduateApplicationUpdate",
        meta: { }
    },
    {
        path: "/graduate/applications",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: ""
        }
    },
    {
        path: "/graduate/application/meetings",
        component: () => import("../pages/Meetings/Index"),
        meta: {
            isAuthenticated: true,
            permission: "graduateapplicationmeeting_index"
        }
    },
    {
        path: "/graduate/application/meetings/report",
        component: () => import("../pages/Meetings/Report"),
        meta: {
            isAuthenticated: true,
            permission: "graduateapplicationmeeting_index"
        }
    },
];

export default routes;

import store from '@/plugins/Store'

const routes = [
    {
        path: "/epayment/login",
        component: () => import("../pages/Login"),
    },
    {
        path: "/epayment/login/:referenceId",
        component: () => import("../pages/Login"),
        props: (route) => ({
            ln: route.query.ln??''
        }),
    },
    {
        path: "/epayment/logout",
        component: () => {
            store.dispatch("epayment/logout");
        },
    },
    {
        path: "/epayment",
        component: () => import("../pages/Index"),
        meta: {isEpaymentAuth: true}
    },
    {
        path: "/epayment/status/:paymentOrderId",
        component: () => import("../pages/Status"),
        meta: {isEpaymentAuth: true}
    },
    {
        path: "/epayment/report",
        component: () => import("../pages/Report"),
        meta: {
            isAuthenticated: true,
            permission: "epayment_report"
        }
    }
];

export default routes;


const routes = [
    {
        path:'/advisor/bulk',
        component:()=>import('../index.vue'),
        meta:{
            isAuthenticated:true
        }
    }
]

export default  routes

const routes = [
    {
        path: "/student/courses",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "studentcourse_index"
        }
    },
    {
        path: "/student/courses/section/updates",
        component: () => import("../pages/SectionUpdate"),
        meta: {
            isAuthenticated: true,
            permission: "studentcourse_sectionupdate"
        }
    },
    {
        path: "/show/student/courses",
        component: () => import("../pages/ShowStudentCourse.vue"),
        meta: {
            isAuthenticated: true,
            permission: 'studentprogram_studentcourseswithattendance'
        }
    },
    {
        path: "/student/courses/student-affairs-entry",
        component: () => import("../pages/StudentAffairsEntry"),
        meta: {
            isAuthenticated: true,
            permission: 'studentcourse_studentaffairsentry'
        }
    }

];

export default routes;

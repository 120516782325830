const routes = [
    {
        path: "/invoices/report",
        component: () => import("../pages/Report"),
        meta: {
            isAuthenticated: true,
            permission: "invoice_report"
        }
    },
    {
        path: "/customer/invoices",
        component: () => import("../pages/Student"),
        meta: {
            isAuthenticated: true,
            permission: "invoice_customer"
        }
    }
];

export default routes;

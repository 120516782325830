const routes = [
    {
        path: "/reports/student",
        component: () => import("../pages/student/Index"),
        meta: {
            isAuthenticated: true,
            permission: "report_studentcollection"
        }
    }
];

export default routes;

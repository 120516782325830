import Api from "@/services/Index";

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/program/module", config);
};

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (data.id) {
        return Api.put("/program/module/" + data.id, data);
    }
    return Api.post("/program/module", data);
};

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/program/module/" + id);
};

const remove = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/program/module/" + id);
};

export default {
    getAll,
    store,
    get,
    remove,
};

const routes = [
    // {
    //     path: "/attendance/entry/weekly/:sectionId?",
    //     component: () => import("../pages/Weekly"),
    //     meta: {
    //         isAuthenticated: true,
    //         permission: "attendance_getattendanceforsection"
    //     }
    // },

    {
        path: "/attendance/entry/:sectionId?",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "attendance_getattendanceforsection"
        }
    }
];

export default routes;

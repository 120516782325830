import ExcelJS from 'exceljs';
import i18n from '@/plugins/i18n'

export default function _downloadFile(response, fileName) {
    const blob = new Blob([response.data], {type: response.headers['content-type']})
    const data = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.click();
    setTimeout(function() {
        window.URL.revokeObjectURL(data);
    }, 100);
}

export async function downloadExcelFile({ data, filename, headers = [], formatting = {} }){
    const workbook = await createExcelWorkbookFromJson({ data, headers, formatting });

    const buffer = await workbook.xlsx.writeBuffer();

    _downloadFile({
        data: buffer,
        headers: {
            'content-type': 'application/json'
        }
    }, filename)
}

export async function createExcelWorkbookFromJson({ data, headers = [], formatting = {} }) {
    if(!data?.length) return;

    const columns = data[0];

    // Object.keys(columns).forEach((key, index) => headers[index].key = key)
    
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(i18n.t('page'), { pageSetup: { fitToPage: true, horizontalCentered: true }});

    worksheet.columns = headers 
    data.forEach(item => {
        worksheet.addRow(item);
    });

    if ('id' in columns) {
        worksheet.getColumn("id").hidden = true;
    }

    for (const property in formatting) {
        const formatToRow = worksheet.getColumn(property)
        formatToRow.eachCell({ includeEmpty: true }, function(cell, rowNumber) {
            if (rowNumber > 1) {
                cell.value = formatting[property](cell.value)
            }
        });
    }

    return workbook;
}
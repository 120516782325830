<template>
    <div>
        <b-modal size="sm" centered :hide-footer="true" :hide-header="true" :no-close-on-backdrop="true" :no-close-on-esc="true"
                 :visible="$store.getters.showConfirmModal">
            <template class="container">
                <div>{{ $t('warning') }}</div>
                <button type="button" aria-label="Close" class="close text-uppercase small" @click="onCancel()">
                    {{ $t('close') }}
                </button>
            </template>
            <template>
                <div class="form-group">{{ $store.getters.confirmModalMessage }}</div>
                <div class="d-flex">
                    <b-button type="button" variant="danger" class="mt-0 btn-block mr-2" @click="onCancel">
                        {{ $t('no') }}
                    </b-button>
                    <b-button type="button" variant="primary" class="mt-0 btn-block ml-2" @click="onOk">
                        {{ $t('yes') }}
                    </b-button>
                </div>
                <!-- content -->
            </template>
        </b-modal>
    </div>
</template>

<script>


export default {
    name: "ConfirmModal",
    props: ['message'],
    data: () => ({}),
    methods: {
        async onOk() {
            await this.$store.getters.confirmModalCallback();
            this.onCancel();
        },
        onCancel() {
            this.$store.commit('closeConfirmModal');
        }

    }
};
</script>

const routes = [
    {
        path: "/exam/schedules/observer",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "examobserver_index"

        }
    },
];

export default routes;

import { ValidationProvider, ValidationObserver } from "vee-validate"
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox"
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete';
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header.vue";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from '@/components/elements/CommonModal'
import SelectDate from "@/components/interactive-fields/SelectDate";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox"
import ProgramModuleSelectbox from "@/components/interactive-fields/ProgramModuleSelectbox"
import RoleSelectbox from "@/components/interactive-fields/RoleSelectbox";

export default ({ Vue }) => {
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
    Vue.component("SemestersSelectbox", SemestersSelectbox);
    Vue.component("ParameterSelectbox", ParameterSelectbox);
    Vue.component("CourseAutoComplete", CourseAutoComplete);
    Vue.component("StaffAutoComplete", StaffAutoComplete);
    Vue.component("FacultySelectbox", FacultySelectbox);
    Vue.component("AppLayout", AppLayout);
    Vue.component("Header", Header);
    Vue.component("HeaderMobile", HeaderMobile);
    Vue.component("DatatableFilter", DatatableFilter);
    Vue.component("Datatable", Datatable);
    Vue.component("CommonModal", CommonModal);
    Vue.component("SelectDate", SelectDate);
    Vue.component("ProgramSelectbox", ProgramSelectbox);
    Vue.component("ProgramModuleSelectbox", ProgramModuleSelectbox);
    Vue.component("RoleSelectbox", RoleSelectbox);
}
const routes = [
    {
        path: "/double-major/application",
        component: () => import("../application/Index"),
        meta: {
            isAuthenticated: true,
            isStudent: true,
            permission: 'minorapplication_studentform'
        }
    }
]
export default routes

const routes = [
    {
        path: "/student/failed",
        component: () => import("../pages/Index.vue"),
        meta: {
            isAuthenticated: true,
            permission:'registration_index'
        }
    },

];

export default routes;

const routes = [
    {
        path: "/double-major/application/preferences/student-affairs",
        component: () => import("../pages/studentAffairs/Index"),
        meta: {
            isAuthenticated: true,
            permission: "doublemajorpreference_indexforstudentaffairs"
        }
    },
    {
        path: "/double-major/application/preferences/prep-school",
        component: () => import("../pages/prepSchool/Index"),
        meta: {
            isAuthenticated: true,permission: "doublemajorpreference_indexforprepschool"
        }
    },
    {
        path: "/double-major/application/preferences/academic-unit-office",
        component: () => import("../pages/academicUnitOffice/Index"),
        meta: {
            isAuthenticated: true,permission:"doublemajorpreference_indexforacademicunitoffice"
        }
    }
];

export default routes;

import {configure, extend} from 'vee-validate';
import {confirmed, double, email, length, max, max_value, min, min_value, numeric, required, required_if} from 'vee-validate/dist/rules';
import i18n from './i18n'

extend('required', required);
extend('email', email);
extend('confirmed', confirmed);
extend('length', length);
extend('required_if', required_if);
extend('numeric', numeric)
extend('double', double)
extend('min', min)
extend('min_value', min_value)
extend('max', max)
extend('max_value', max_value)

configure({
    defaultMessage: (field, values) => {
        return i18n.t(`validation.messages.${values._rule_}`, values);
    }
});

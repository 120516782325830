<template>
    <div>
        <multiselect
            :placeholder="$t('select')"
            v-model="selected"
            :options="options"
            :class="validateError != '' ? 'box-border-color' : ''"
            @input="handleInput($event, multiple)"
            label="text"
            track-by="value"
            :multiple="multiple"
            :close-on-select="!multiple"
            :clear-on-select="!multiple"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
        >
            <template slot="selection" slot-scope="{ values, search, isOpen }" v-if="multiple">
                <span class="multiselect__single" v-if="values.length && !isOpen">
	                {{ translateNSelected(values, 3) }}
                </span>
            </template>
            <span slot="noOptions">
                {{ $t("no_options") }}
            </span>
            <span slot="noResult">
                {{ $t("no_result") }}
            </span>
        </multiselect>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
    </div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";

// Services
import SemesterService from "@/services/SemesterService";

export default {
    props: {
        value: {
            default: null,
        },
        multiple: {
            default: false,
        },
        validateError: {
            type: String,
            default: "",
        },
        nonFilter: {
            type: String,
            default: null,
        },
        setActive: {
            default: false,
        },
        withoutYears: {
            type: Boolean,
            default: false
        },
        withoutSummer: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        currentValue: {
            get: function () {
                return this.value
            },
            set: function (value) {
                this.$emit("input", value);
            }
        }
    },
    watch: {
        value: function (newValue) {
            this.selected = this.setSelected(newValue, this.options, this.multiple)
        },
        withoutYears: function () {
            this.getOptions()
        }
    },
    data() {
        return {
            options: [],
            selected: null,
            active: null,
        }
    },
    created() {
        this.getOptions();
    },
    methods: {
        translateNSelected: translateNSelected,
        handleInput: handleInput,
        setSelected: setSelected,
        getOptions() {
            const key = 'semesters';
            let local = this.getWithExpiry(key);
            if (local) {
                this.setOptions(local);
                this.setLocalSelected();
                return;
            }
            this.options = [];
            SemesterService.semesters()
                .then((response) => {
                    const data = response.data.data;
                    this.setWithExpiry(key, data);
                    this.setOptions(data);
                })
                .then(() => {
                    this.setLocalSelected();
                })
        },
        setOptions(data) {
            data.options.forEach((item) => {
                this.options.push({
                    value: item.id,
                    text: item.academic_year + " " + item.semester_text,
                });
            })
            if (data.active) {
                this.active = data.active

                if (this.setActive && !this.multiple && !this.value) {
                    this.currentValue = data.active;
                }
            }
            if (this.withoutYears) {
                this.options = this.options.filter(o => o.text.indexOf('Yıllık') <= -1)
            }
            if (this.withoutSummer) {
                this.options = this.options.filter(o => o.text.indexOf('Yaz') <= -1)
            }
        },
        setLocalSelected() {
            this.selected = this.setSelected(this.value, this.options, this.multiple)
            if (this.selected && this.selected.length == 0 && this.multiple == false && this.value == null && this.active) {
                this.selected = this.options.filter(item => item.value == this.active)
            }
        }
    }
}
</script>

import Api from '@/services/Index';

// Student
const start = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfers/start', formData, {
        headers: {
            // remove headers
        }
    });
}

// Auth
const verify = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfers/verify', formData);
}

const login = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfers/login', formData);
}

// 1. Save
const save = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfers/save', data);
}

// 3. Documents
const requiredDocuments = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfer/documents', formData);
}

const uploadDocument = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfer/upload', formData);
}

const downloadDocument = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfer/download', formData,{responseType: 'arraybuffer'});
}
const deleteDocument = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/undergraduate/transfer/delete/document', {data: {...formData}});
}

const sendApprove = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfer/send-approve', formData);
}

// Admin
const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/undergraduate/transfers', config);
}

const approve = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfer/approve', config);
}

// Student Affairs
const studentAffairsGetAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/undergraduate/transfer/index/student-affairs', config);
}

const studentAffairsApprove = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfer/approve/student-affairs', config);
}

// Prep School
const prepSchoolGetAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/undergraduate/transfer/index/prep-school', config);
}
const prepSchoolApprove = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfer/approve/prep-school', config);
}

// Academic Unit Office
const academicUnitOfficeGetAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/undergraduate/transfer/index/academic-unit-office', config);
}
const academicUnitOfficeApprove = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/undergraduate/transfer/approve/academic-unit-office', config);
}

export default {
    start,
    verify,
    login,
    save,

    requiredDocuments,
    uploadDocument,
    downloadDocument,
    deleteDocument,
    sendApprove,

    getAll,
    approve,

    studentAffairsGetAll,
    studentAffairsApprove,

    prepSchoolGetAll,
    prepSchoolApprove,

    academicUnitOfficeGetAll,
    academicUnitOfficeApprove

}

<template>
	<div>
		<multiselect :placeholder="$t('select')" v-model="selected" :options="options"
			:class="validateError != '' ? 'box-border-color' : ''" @input="handleInput($event, multiple)" label="text"
			track-by="value" :multiple="multiple" :close-on-select="!multiple" :clear-on-select="!multiple"
			:select-label="''" :selected-label="''" :deselect-label="''">
			<template slot="selection" slot-scope="{ values, search, isOpen }" v-if="multiple">
				<span class="multiselect__single" v-if="values.length && !isOpen">
					{{ translateNSelected(values) }}
				</span>
			</template>
			<span slot="noOptions">{{ $t("no_options") }}</span>
			<span slot="noResult">{{ $t("no_result") }}</span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError" />
	</div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";

// Services
import ProgramModuleService from "@/services/ProgramModuleService";

// Other
import qs from "qs";

export default {
	name: "ProgramModuleSelectBox",
	props: {
		is_faculty_code_required: {
			type: Boolean,
			default: false,
		},
		faculty_code: {
			default: null,
		},
		major_type: {
			type: String,
			default: null,
		},
		value: {
			default: null,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		validateError: {
			type: String,
			default: '',
		},
		public: {
			type: Boolean,
			default: false
		},
		isActive: {
			type: Boolean,
			default: false,
		},
		valProperty: {
			type: String,
			default: 'id'
		}
	},
	data() {
		return {
			selected: null,
			options: [],
		}
	},
	watch: {
		value: function (newValue) {
			this.selected = this.setSelected(newValue, this.options, this.multiple)
		},
		faculty_code: function () {
			this.getOptions()
		},
	},
	created() {
		this.getOptions()
	},
	methods: {
		translateNSelected,
		handleInput,
		setSelected,
		getOptions() {
			if (this.is_faculty_code_required && this.faculty_code == null) {
				return;
			}

			const config = {
				params: {
					filter: {
						program_code: this.program_code,
						// major_type: this.major_type,
						// status: this.isActive ? 'a' : ''
					},
					sort: 'program_code',
					limit: -1,
				},
				paramsSerializer: (params) => qs.stringify(params, { encode: false })
			}

			this.clearOptions();
			ProgramModuleService.getAll(config)
				.then((response) => {
					this.options = [];
					const data = response.data.data;
					data.map((item) => {
						this.options.push({
							value: item[this.valProperty],
							text: item.module_code + ' - ' + item.module_name,
						})
					})
				})
				.then(() => {
					this.selected = this.setSelected(this.value, this.options, this.multiple)
				})

		},

		clearOptions() {
			this.selected = null;
			this.options = [];
		}
	}
}
</script>

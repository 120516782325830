import store from '@/plugins/Store'

const routes = [
    // Admin
        // Admin
        {
            path: '/undergraduate/transfer',
            component: () => import('../pages/admin/admin/Index'),
            meta: {
                isAuthenticated: true,
                permission: "undergraduatetransfer_index"
            }
        },

        // Student Affairs
        {
            path: '/undergraduate/transfer/student-affairs',
            component: () => import('../pages/admin/admin/approve/studentAffairs/Index'),
            meta: {
                isAuthenticated: true,
                permission: "undergraduatetransfer_indexforstudentaffairs"
            }
        },

        // Prep School
        {
            path: '/undergraduate/transfer/prep-school',
            component: () => import('../pages/admin/admin/approve/prepSchool/Index'),
            meta: {
                isAuthenticated: true,
                permission: "undergraduatetransfer_indexforprepschool"
            }
        },

        // Academic Unit Office
        {
            path: '/undergraduate/transfer/academic-unit-office',
            component: () => import('../pages/admin/admin/approve/academicUnitOffice/Index'),
            meta: {
                isAuthenticated: true,
                permission: "undergraduatetransfer_indexforacademicunitoffice"
            }
        },

        // Admin
        {
            path: '/undergraduate/transfer/start',
            component: () => import('../pages/admin/student/Index'),
            meta: {
                isAuthenticated: true,
                permission: "undergraduatetransfer_start"
            }
        },

    // Student Transfer
    {
        path: '/transfer/login',
        component: () => import('../pages/transfer/Login')
    },
    {
        path: '/transfer/register',
        component: () => import('../pages/transfer/Register')
    },
    {
        path: '/transfer/logout',
        component: () => {
            store.dispatch('undergraduateTransfer/logout');
        }
    },
    {
        path: '/transfer',
        component: () => import('../pages/transfer/Index'),
        meta: {
            isUndergraduateTransferLogin: true
        }
    }
];

export default routes;

<template>
	<div class="bg-transparent small text-uppercase d-flex align-items-center d-lg-none mb-4">
		<h5 class="m-0" v-if="title">
			{{ changeTitle(title) }}
		</h5>
		<b-button variant="outline-secondary secondary-border-light" class="w-40 h-40 mx-2" v-if="backVisibleState">
                            <i class="header-back-icon ri-arrow-left-s-line "
                                @click="$router.back()"></i>
                        </b-button>
		<div class="d-inline-flex">
			<b-button variant="outline-secondary secondary-border-light" class="w-40 h-40 mr-2"
				@click="$emit('new-button-click')" v-if="isNewButton">
				<span class="d-flex">
					<i class="ri-add-line"></i>
				</span>
			</b-button>
			<b-button variant="outline-secondary secondary-border-light" class="w-40 h-40 mr-2"
				@click="$emit('rank-button-click')" v-if="isRankButton">
				<span>
					<i class="ri-sort-desc"></i>
				</span>
			</b-button>
			<b-button variant="outline-secondary secondary-border-light" class="w-40 h-40 mr-2"
				@click="$emit('import-button-click')" v-if="isImportButton">
				<span class="d-flex">
					<i class="ri-upload-line"></i>
				</span>
			</b-button>
			<b-dropdown variant="outline-secondary secondary-border-light" class="h-40 mr-2" v-if="isColumns">
				<template #button-content>
					<span class="d-flex">
						<i class="ri-layout-column-line"></i>
					</span>
				</template>
				<div class="checkbox-list width-300">
					<div class="px-4 py-2">
						<slot name="columns"></slot>
					</div>
				</div>
			</b-dropdown>
			<b-button variant="outline-secondary secondary-border-light" class="w-40 h-40 mr-2" @click="filterDivStatusHide"
				v-if="isFilter">
				<span>
					<i class="ri-filter-3-line"></i>
				</span>
			</b-button>
			<b-button variant="outline-secondary secondary-border-light w-40" class="mr-2" @click="$emit('expand-all')"
				v-if="isExpandAll">
				<span class="d-flex">
					<i class="ri-arrow-down-s-line font-size-20"></i>
				</span>
			</b-button>
			<b-button variant="outline-secondary secondary-border-light w-40" class="mr-2" @click="$emit('collapse-all')"
				v-if="isCollapseAll">
				<span class="d-flex">
					<i class="ri-arrow-up-s-line font-size-20"></i>
				</span>
			</b-button>
			<b-button variant="danger" class="mr-2 w-40" @click="$emit('move-cancel')" v-if="isCancelToMove">
				<span class="d-flex">
					<i class="ri-close-line font-size-20"></i>
				</span>
			</b-button>
			<b-dropdown variant="outline-secondary secondary-border-light" class="w-40 h-40 mr-2"
				v-if="actions && filteredActions.length > 0">
				<b-dropdown-item v-for="(action, actionKey) in filteredActions" :key="'actionMobile' + actionKey"
					@click="action.callback">
					{{ action.text }}
				</b-dropdown-item>
			</b-dropdown>
			<div class="d-inline-flex" v-if="isBackButton">
				<b-button variant="outline-secondary secondary-border-light" class="w-40 h-40 mr-2" @click="backButton">
					<i class="ri-arrow-left-line"></i>
				</b-button>
			</div>
		</div>
	</div>
</template>
<script>
import CommonModal from "@/components/elements/CommonModal.vue";

export default {
	components: {
		CommonModal
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		isNewButton: {
			type: Boolean,
			default: false,
		},
		isRankButton: {
			type: Boolean,
			default: false,
		},
		isImportButton: {
			type: Boolean,
			default: false,
		},
		isColumns: {
			type: Boolean,
			default: false,
		},
		isFilter: {
			type: Boolean,
			default: true,
		},
		isExpandAll: {
			type: Boolean,
			default: false
		},
		isCollapseAll: {
			type: Boolean,
			default: false
		},
		isCancelToMove: {
			type: Boolean,
			default: false
		},
		actions: {
			type: Array,
			default: null
		},
		isBackButton: {
			type: Boolean,
			default: false,
		},
		backButton: {
			type: Function,
			default: function () {
			}
		}
	},
	data() {
		return {
			filterDivStatus: true,
		}
	},
	computed: {
		backVisibleState() {

			if (this.$route.path.includes("dashboard")) {
				return false
			}
			return true
		},
		filteredActions() {
			let arr = [];
			this.actions.forEach(item => {
				let add = false;
				if (!item.permission || (item.permission && this.checkPermission(item.permission))) {
					add = true;
				}
				if (add && typeof item.show == 'function') {
					add = item.show();
				}
				if (add) {
					arr.push(item);
				}
			})
			return arr;
		}
	},
	mounted() {
		this.$emit("filter:show", this.filterDivStatus);
	},
	methods: {
		changeTitle(title) {
			title = title.replace(/i/g, 'ı')
			return title
		},
		filterDivStatusHide() {
			this.filterDivStatus = !this.filterDivStatus;
			this.$emit("filter-div-status", this.filterDivStatus);
		}
	}
}
</script>


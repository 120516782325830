const translations = {
    code: 'en',
    messages: {
        confirmed: 'Confirmation does not match',
        email: 'Must be a valid email',
        required_if: 'This field is required',
        required: 'This field is required',
        length: 'Must be "{length}" long',
        numeric: 'This field may only contain numeric characters',
        double: 'This field must be a valid decimal',
        min: 'This field must be at least {length} characters',
        max: 'This field may not be greater than {length} characters',
        max_value:'This field must be less than {_value_}',
        min_value:'This field must be greater than {_value_}'
    }
}

export default translations

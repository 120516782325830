const routes = [
    {
        path: "/registrations/index",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "registration_index",
        },
    },
    {
        path: "/registrations/documents",
        component: () => import("../pages/Documents/Index"),
        meta: {
            isAuthenticated: true,
            permission: "registrationdocumenttype_index",
        },
    },
    {
        path: "/registrations/proficiency",
        component: () => import("../pages/Proficiency"),
        meta: {
            isAuthenticated: true,
            permission: "registration_proficiency",
        },
    },
    {
        path: "/registrations/proficiency/:id",
        component: () => import("../pages/ProficiencyDetail"),
        meta: {
            isAuthenticated: true,
            permission: "registration_proficiency",
        },
    },
    {
        path: "/registrations/new/register",
        component: () => import("../pages/NewRegistration"),
        meta: {
            isAuthenticated: true,
            permission: "registration_register",
        },
    },
    {
        path: "/registrations/new/register/:id/documents",
        component: () => import("../pages/NewRegistrationDocuments"),
        meta: {
            isAuthenticated: true,
            permission: "registration_document",
        },
    },
    {
        path: "/registrations/show/register/:id",
        component: () => import("../pages/RegistrationShow"),
        meta: {
            isAuthenticated: true,
            permission: "registration_show",
        },
    },
    {
        path: "/registrations/new/register/:id/summary",
        component: () => import("../pages/NewRegistrationSummary"),
        meta: {
            isAuthenticated: true,
            permission: "registration_show",
        },
    },
    {
        path: "/registrations/update/register/:id",
        component: () => import("../pages/UpdateRegistration"),
        meta: {
            isAuthenticated: true,
            permission: "registration_update",
        },
    },
];

export default routes;
